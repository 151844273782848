import * as yup from 'yup';

export const uploadBankSchema = yup.object().shape({
  pdf: yup
    .mixed()
    .required('File is required')
    .test('fileType', 'Only PDF files are allowed', value => {
      if (value && value[0]) {
        return value[0].type === 'application/pdf';
      }
      return false;
    }),
  statement_type_id: yup.number().required('Statement type is required'),
  national_id: yup.string().required('National ID is required'),
  start_date: yup.string().required('Start date is required'),
  end_date: yup
    .string()
    .required('End date is required')
    .test('date-range', 'End date must be after start date', function (value) {
      const { start_date } = this.parent;

      const start = getDate(start_date);
      const end = getDate(value);

      // Use the 'strict' option to enforce format matching
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

      // Check if both dates match the expected format
      if (!start.match(dateFormat) || !end.match(dateFormat)) {
        return false;
      }

      // Compare the dates as strings (lexicographic comparison)
      return start <= end;
    }),
  pin: yup.string(),
});
export const uploadMpesaSchema = yup.object().shape({
  pdf: yup
    .mixed()
    .required('File is required')
    .test('fileType', 'Only PDF files are allowed', value => {
      if (value && value[0]) {
        return value[0].type === 'application/pdf';
      }
      return false;
    }),
  statement_type_id: yup.number().required('Statement type is required'),
  start_date: yup.string().required('Start date is required'),
  end_date: yup
    .string()
    .required('End date is required')
    .test('date-range', 'End date must be after start date', function (value) {
      const { start_date } = this.parent;

      const start = getDate(start_date);
      const end = getDate(value);

      // Use the 'strict' option to enforce format matching
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

      // Check if both dates match the expected format
      if (!start.match(dateFormat) || !end.match(dateFormat)) {
        return false;
      }

      // Compare the dates as strings (lexicographic comparison)
      return start <= end;
    }),
  phone_number: yup.string().required('Phone number is required'),
  pin: yup.string(),
});

export const uploadFinancialSchema = yup.object().shape({
  pdf: yup
    .mixed()
    .required('File is required')
    .test('fileType', 'Only PDF files are allowed', value => {
      if (value && value[0]) {
        return value[0].type === 'application/pdf';
      }
      return false;
    }),
  start_date: yup.string().required('Start date is required'),
  end_date: yup
    .string()
    .required('End date is required')
    .test('date-range', 'End date must be after start date', function (value) {
      const { start_date } = this.parent;

      const start = getDate(start_date);
      const end = getDate(value);

      // Use the 'strict' option to enforce format matching
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

      // Check if both dates match the expected format
      if (!start.match(dateFormat) || !end.match(dateFormat)) {
        return false;
      }

      // Compare the dates as strings (lexicographic comparison)
      return start <= end;
    }),
});

export const getDate = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
