import React, { createRef } from 'react';
import { useUploadStateMutation } from '../../../Redux/features/user/uploads';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getDate, uploadFinancialSchema } from './finance-schema';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ButtonLoader from '../../Buttons/ButtonLoader';

const FinancialStatement = ({ statementType, userId }) => {
  const [uploadState, { isLoading: loading }] = useUploadStateMutation();
  const docRef = createRef();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(uploadFinancialSchema),
    mode: 'onChange',
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = async data => {
    const { pdf, start_date, end_date, ...rest } = data;
    const inputData = {
      pdf: pdf[0],
      startDate: getDate(start_date),
      endDate: getDate(end_date),
      ...rest,
      statement_type_id: statementType.value,
    };
    const type = 'financial';
    try {
      await uploadState({ statement: inputData, type, userId }).unwrap();
      toast.success('Your financial statement has been uploaded successfully');
      reset();
      goBack();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <div className='col-lg-12'>
      <div className='card'>
        <div className='card-body'>
          <div className='wizard-main' id='w-horizontal'>
            <div className='step-app'>
              <div className='step-content'>
                <div className={`step-tab-panel active`} data-step='step1'>
                  <form onSubmit={handleSubmit(onSubmit)} id='upload-bank-form'>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type={'date'}
                          placeholder='Enter date'
                          {...register('start_date')}
                        />
                        {errors.start_date?.message && (
                          <Form.Text>{errors.start_date?.message}</Form.Text>
                        )}
                      </div>
                      <div className='col-md-6  mb-3'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type={'date'}
                          placeholder='Enter date'
                          {...register('end_date')}
                        />
                        {errors.end_date?.message && (
                          <Form.Text>{errors.end_date?.message}</Form.Text>
                        )}
                      </div>
                      <div className={'col-md-6 mb-3'}>
                        <Form.Label>Upload document</Form.Label>
                        <Form.Control
                          ref={docRef}
                          type='file'
                          name={'pdf'}
                          {...register('pdf')}
                        />
                        {errors.pdf?.message && (
                          <Form.Text>{errors.pdf?.message}</Form.Text>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-danger mx-5'
                  style={{ color: 'white' }}
                  onClick={goBack}>
                  Close
                </button>
                <button
                  disabled={!isDirty || !isValid || loading}
                  type={'submit'}
                  form={'upload-bank-form'}
                  className='btn btn-primary'>
                  <ButtonLoader isLoading={loading} text={'Upload'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialStatement;
