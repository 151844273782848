import React from "react";
import { Dropdown } from "react-bootstrap";
import flag from "../../assets/images/flag/GB.png";
import F1 from "../../assets/images/flag/GB.png";
import F2 from "../../assets/images/flag/DE.png";
import F3 from "../../assets/images/flag/FR.png";
import F4 from "../../assets/images/flag/IT.png";
import F5 from "../../assets/images/flag/RU.png";
import { Link } from "react-router-dom";

function AuthHeader() {
  return (
    <div className="container-xxl">
      <div className="row align-items-center">
        <div className="col">
          <Link
            to={process.env.PUBLIC_URL + "/"}
            className="d-flex align-item-center">
            <i className="fa fa-gg-circle fs-3"></i>
            <h5 className="mb-0 mt-1 mx-2">Melanin Kapital</h5>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default AuthHeader;
