import { apiSlice } from '../../app/api/apiSlice';

const statementUploadSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadState: builder.mutation({
      query: ({ statement, type, userId }) => {
        const formData = new FormData();
        for (const statementKey in statement) {
          formData.append(statementKey, statement[statementKey]);
        }
        if (type === 'bank') {
          return {
            url: `bank/statement/upload/${userId}`,
            method: 'POST',
            body: formData,
          };
        } else if (type === 'mpesa') {
          return {
            url: `mpesa/statement/upload/${userId}`,
            method: 'POST',
            body: formData,
          };
        } else if (type === 'financial') {
          return {
            url: `financial/statements/upload/${userId}`,
            method: 'POST',
            body: formData,
          };
        }
      },
    }),
  }),
});

export const { useUploadStateMutation } = statementUploadSlice;
