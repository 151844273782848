import { apiSlice } from '../../app/api/apiSlice';
import { SMT_TYPE } from '../../../constants';

const statementTypesApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getStatementTypes: build.query({
      query: (page = 1, per_page = 100) =>
        `/statement/type/list?page=${page}&per_page=${per_page}`,
      transformResponse: response => response?.response,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: SMT_TYPE,
                id,
              })),
              { type: SMT_TYPE, id: 'LIST' },
            ]
          : [{ type: SMT_TYPE, id: 'LIST' }],
    }),
    addNewStatementType: build.mutation({
      query: statementType => {
        const formData = new FormData();
        for (const statementTypeKey in statementType) {
          formData.append(statementTypeKey, statementType[statementTypeKey]);
        }
        return {
          url: '/statement/type/create',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: SMT_TYPE, id: 'LIST' }],
    }),
    updateStatementType: build.mutation({
      query: statementType => {
        const formData = new FormData();
        for (const statementTypeKey in statementType.item) {
          formData.append(
            statementTypeKey,
            statementType.item[statementTypeKey],
          );
        }
        return {
          url: `/statement/type/${statementType.id}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: SMT_TYPE, id: arg.id }],
    }),
    deleteStatementType: build.mutation({
      query: id => ({
        url: `/statement/type/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: SMT_TYPE, id: arg.id },
        { type: SMT_TYPE, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetStatementTypesQuery,
  useAddNewStatementTypeMutation,
  useUpdateStatementTypeMutation,
  useDeleteStatementTypeMutation,
} = statementTypesApiSlice;
