import Badge from 'react-bootstrap/Badge';

export const LoanApplicationColumns = () => {
  return [
    {
      name: 'ID',
      selector: row => row?.id,
      sortable: true,
    },
    {
      name: 'Loan Product',
      selector: row => row?.loan_product.name,
      sortable: true,
    },
    {
      name: 'Business',
      selector: row => row?.business_id,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => `Ksh ${Number(row?.amount).toLocaleString()}`,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row =>
        row?.status.id === 19 ? (
          <Badge className={'fs-8'} bg='info'>
            Pending
          </Badge>
        ) : (
          <Badge bg='success'>Approved</Badge>
        ),
      sortable: true,
    },
    {
      name: 'Approval Date',
      selector: row => row.cio_approval_date ?? '_ _',
      sortable: true,
    },
  ];
};
