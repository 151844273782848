import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      query: credentials => {
        const data = new FormData();
        data.append('email', credentials.email);
        data.append('password', credentials.password);
        return {
          url: 'admin/auth',
          method: 'POST',
          body: data,
        };
      },
    }),
    registerUser: build.mutation({
      query: credentials => {
        const data = new FormData();
        data.append('email', credentials.email);
        data.append('password', credentials.password);
        data.append('name', credentials.name);
        return {
          url: '/signup',
          method: 'POST',
          body: data,
        };
      },
    }),
    passwordReset: build.mutation({
      query: payload => {
        const data = new FormData();
        for (const payloadKey in payload) {
          data.append(payloadKey, payload[payloadKey]);
        }
        return {
          url: '/auth/password/reset',
          method: 'POST',
          body: data,
        };
      },
    }),
    forgotPassword: build.mutation({
      query: payload => {
        const data = new FormData();
        data.append('login', payload);
        return {
          url: '/auth/password/forgot',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterUserMutation,
  useForgotPasswordMutation,
  usePasswordResetMutation,
} = authApiSlice;
