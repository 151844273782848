import { useGetUserQuery } from '../Redux/features/user/userApiSlice';
import { Spinner } from 'react-bootstrap';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CustomLoader } from './LoanApplicationsList/LoanApplicationsList';
import Header from '../Component/Comman/Header';
import EditFinances from '../Component/LoanApplications/CompanyDocs/edit-finances';

const FinancialProviders = lazy(() =>
  import('./financial-providers/financial-providers'),
);
const Roles = lazy(() => import('./roles/roles'));
const EventsRegister = lazy(() => import('./events-register/events-register'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const EntrepreneursList = lazy(() =>
  import('./Entrepreneurs/EntrepreneursList'),
);
const Academy = lazy(() => import('./Academy/Academy'));
const Future = lazy(() => import('./Future/Future'));
const Admin = lazy(() => import('./Admin/Admin'));
const Lenders = lazy(() => import('./Lenders/Lenders'));
const Investors = lazy(() => import('./Trade/Investors'));
const MarginTrade = lazy(() => import('./Trade/MarginTrade'));
const CryptoPay = lazy(() => import('./Finace/CryptoPay'));
const Calendar = lazy(() => import('./App/Calender'));
const Chat = lazy(() => import('./App/Chat'));
const BootStrapForm = lazy(() => import('./FormInput/BootStrapForm'));
const CustomeForm = lazy(() => import('./FormInput/CustomeForm'));
const VizardForm = lazy(() => import('./FormInput/VizardForm'));
const WidgetExample = lazy(() =>
  import('./Widget/WidgetExample/WidgetExample'),
);
const ChartsExample = lazy(() =>
  import('./Widget/ChartsExample/ChartsExample'),
);
const PricePlane = lazy(() => import('./Widget/PricePlane/PricePlane'));
const ProfilePage = lazy(() => import('./Widget/Profile Page/ProfilePage'));
const TableExample = lazy(() => import('./Widget/TableExample/TableExample'));
const Help = lazy(() => import('./Help/Help'));
const SalarySlip = lazy(() => import('./SalarySlip/SalarySlip'));
const Documentation = lazy(() => import('./Documentation/Documentation'));
const Expenses = lazy(() => import('./Expenses/Expenses'));
const Changelog = lazy(() => import('./Changelog/Changelog'));
const Invoice = lazy(() => import('./Invoice/Invoice'));
const StaterPage = lazy(() => import('./Stater Page/StaterPage'));
const Alerts = lazy(() => import('./Ui Component/Alerts'));
const Badges = lazy(() => import('./Ui Component/Badge'));
const Breadcrumb = lazy(() => import('./Ui Component/Breadcrumb'));
const Buttons = lazy(() => import('./Ui Component/Buttons'));
const Cards = lazy(() => import('./Ui Component/Card'));
const Carousel = lazy(() => import('./Ui Component/Carousel'));
const Collapse = lazy(() => import('./Ui Component/Collapse'));
const Dropdowns = lazy(() => import('./Ui Component/Dropdowns'));
const ListGroup = lazy(() => import('./Ui Component/ListGroup'));
const ModalUI = lazy(() => import('./Ui Component/Modal'));
const NavbarUI = lazy(() => import('./Ui Component/Navbar'));
const NavsUI = lazy(() => import('./Ui Component/Navs'));
const PaginationUI = lazy(() => import('./Ui Component/Pagination'));
const PopoversUI = lazy(() => import('./Ui Component/Popovers'));
const ProgressUI = lazy(() => import('./Ui Component/Progress'));
const Scrollspy = lazy(() => import('./Ui Component/Scrollspy'));
const SpinnersUI = lazy(() => import('./Ui Component/Spinners'));
const ToastsUI = lazy(() => import('./Ui Component/Toasts'));
const Icons = lazy(() => import('./FontIcon/Icon'));
const BootStrapIcon = lazy(() => import('./FontIcon/BootStrapIcon'));
const FontAwesomeIcon = lazy(() => import('./FontIcon/FontAwesomeIcon'));
const ToDoList = lazy(() => import('./Widget/ToDoList/ToDoList'));
const WalletPage = lazy(() => import('./WalletPage/WalletPage'));
const Reward = lazy(() => import('./Reward/Reward'));
const SecurityPage = lazy(() => import('./SecurityPage/SecurityPage'));
const Identification = lazy(() => import('./Identification/Identification'));
const ReferalPage = lazy(() => import('./ReferalPage/ReferalPage'));
const SingleTicket = lazy(() => import('./Ticket/SingleTicket'));

const Courses = lazy(() => import('../Component/Academy/Courses/Courses'));
const Lesson = lazy(() => import('../Component/Academy/Lessons/Lesson'));
const TicketList = lazy(() => import('../Component/Ticket/TickectList'));
const LoanProducts = lazy(() => import('./LoanProducts/LoanProducts'));
const LoanTransactions = lazy(() =>
  import('./LoanTransactions/LoanTransactions'),
);
const LoanApplicationsList = lazy(() =>
  import('./LoanApplicationsList/LoanApplicationsList'),
);
const Posts = lazy(() => import('./Posts/Posts'));
const Faqs = lazy(() => import('./Faqs/Faqs'));
const Experts = lazy(() => import('./Experts/Experts'));
const BusinessApps = lazy(() => import('./Apps/BusinessApps'));
const LoanApplications = lazy(() =>
  import('./LoanApplications/LoanApplications'),
);
const BusinessProfile = lazy(() =>
  import('./Entrepreneurs/BusinessProfile/BusinessProfile'),
);
const Vendors = lazy(() => import('./Vendors/Vendors'));
const Programs = lazy(() => import('./Programs/Programs'));
const Questions = lazy(() => import('./Questions/Questions'));
const Options = lazy(() => import('./Options/Options'));
const FuelType = lazy(() => import('./FuelType/FuelType'));
const ItemType = lazy(() => import('./ItemType/ItemType'));
const Ghg = lazy(() => import('./GHG/GHG'));
const Items = lazy(() => import('./Items/Items'));
const ItemGhg = lazy(() => import('./ItemGhg/ItemGhg'));
const SMERecords = lazy(() => import('./SMERecords/SMERecords'));
const VerifiedGhg = lazy(() => import('./VerifiedGhg/VerifiedGhg'));
const GhgTransaction = lazy(() => import('./GhgTransactions/GhgTransaction'));
const TokenWallets = lazy(() => import('./TokenWallets/TokenWallets'));
const GeneratedGhg = lazy(() => import('./GeneratedGhg/GeneratedGhg'));
const UnGhg = lazy(() => import('./UnGhg'));
const StatementType = lazy(() => import('./StatementType/StatementType'));
const KYCAnalysis = lazy(() => import('./KYCAnalysis/KYCAnalysis'));
const MpesaIndex = lazy(() => import('./Mpesa/MpesaIndex'));
const BankIndex = lazy(() => import('./Bank/BankIndex'));
const FstatementsIndex = lazy(() =>
  import('./FinancialStatements/FstatementsIndex'),
);
const CategoryAmountsList = lazy(() =>
  import('./CategoryAmounts/CategoryAmountsList'),
);
const StatementCategories = lazy(() =>
  import('./StatementCategories/StatementCategories'),
);
const CreditScores = lazy(() => import('./CreditScores/CreditScores'));
const Events = lazy(() => import('./events/events'));
const AcademyProgress = lazy(() =>
  import('./academy-progress/academy-progress'),
);
const ProgressDetail = lazy(() => import('./academy-progress/progress-detail'));

const MainIndex = props => {
  const { activekey } = props;
  const baseUrl = process.env.PUBLIC_URL;
  const { data, isLoading } = useGetUserQuery();
  let content;

  if (isLoading) {
    content = (
      <Spinner
        animation='grow'
        className='me-3'
        style={{
          width: '3rem',
          height: '3rem',
          position: 'absolute',
          top: '50px',
          left: '50%',
        }}
      />
    );
  } else if (data) {
    content = (
      <div className='main px-lg-4 px-md-4'>
        {activekey === '/chat' || activekey === '/documentation' ? (
          ''
        ) : (
          <Header userData={data} />
        )}
        <div className='body d-flex py-3 '>
          <Suspense fallback={<CustomLoader />}>
            <Routes>
              <Route
                exact
                path={`${baseUrl}/`}
                element={<Dashboard userData={data} />}
              />
              <Route
                path={`${baseUrl}/loan-applications`}
                element={<LoanApplicationsList />}
              />
              <Route
                path={`${baseUrl}/loan-applications/:id`}
                element={<LoanApplications />}
              />
              <Route
                path={`${baseUrl}loan-products`}
                element={<LoanProducts />}
              />
              <Route path={`${baseUrl}vendors`} element={<Vendors />} />
              <Route
                path={`${baseUrl}loan-transactions`}
                element={<LoanTransactions />}
              />
              <Route
                exact
                path={`${baseUrl}/entrepreneurs`}
                element={<EntrepreneursList />}
              />
              <Route
                path={`${baseUrl}/entrepreneurs/:id`}
                element={<BusinessProfile />}
              />

              <Route
                path={`${baseUrl}/entrepreneurs/:id/upload-financial-statements`}
                element={<EditFinances />}
              />
              <Route path={`${baseUrl}/academy`} element={<Academy />}>
                <Route index element={<Courses />} />
                <Route path={'courses/:id'}>
                  <Route index element={<Lesson />} />
                  <Route path={'question/:lessonId'}>
                    <Route index element={<Questions />} />
                    <Route path={'options/:questionId'} element={<Options />} />
                  </Route>
                </Route>
              </Route>

              <Route path={`${baseUrl}/masterclass-progress`}>
                <Route index element={<AcademyProgress />} />
                <Route path={':userId'} element={<ProgressDetail />} />
              </Route>

              <Route path={`${baseUrl}/apps`} element={<BusinessApps />} />
              <Route path={`${baseUrl}/experts`} element={<Experts />} />
              <Route path={`${baseUrl}/posts`} element={<Posts />} />
              <Route path={`${baseUrl}/faqs`} element={<Faqs />} />
              <Route path={`${baseUrl}/programs`} element={<Programs />} />
              <Route
                path={`${baseUrl}/financial-providers`}
                element={<FinancialProviders />}
              />

              <Route path={`${baseUrl}/fuel-type`} element={<FuelType />} />
              <Route path={`${baseUrl}/item-type`} element={<ItemType />} />
              <Route path={`${baseUrl}/ghg-released`} element={<Ghg />} />
              <Route path={`${baseUrl}/items`} element={<Items />} />
              <Route path={`${baseUrl}/item-ghg`} element={<ItemGhg />} />
              <Route
                path={`${baseUrl}/statement-types`}
                element={<StatementType />}
              />
              <Route
                path={`${baseUrl}/kyc-analysis`}
                element={<KYCAnalysis />}
              />
              <Route
                path={`${baseUrl}/mpesa-analysis-results`}
                element={<MpesaIndex />}
              />
              <Route
                path={`${baseUrl}/bank-analysis-results`}
                element={<BankIndex />}
              />
              <Route
                path={`${baseUrl}/sme-statements`}
                element={<FstatementsIndex />}
              />
              <Route
                path={`${baseUrl}/statement-categories`}
                element={<StatementCategories />}
              />
              <Route
                path={`${baseUrl}category-amounts`}
                element={<CategoryAmountsList />}
              />
              <Route
                path={`${baseUrl}/credit-scores`}
                element={<CreditScores />}
              />
              <Route
                path={`${baseUrl}/cumulative-sme-records`}
                element={<SMERecords />}
              />
              <Route
                path={`${baseUrl}/verified-ghg-saving`}
                element={<VerifiedGhg />}
              />
              <Route
                path={`${baseUrl}ghg-transactions`}
                element={<GhgTransaction />}
              />
              <Route
                path={`${baseUrl}/token-wallets`}
                element={<TokenWallets />}
              />
              <Route
                path={`${baseUrl}/generated-ghg-tokens`}
                element={<GeneratedGhg />}
              />
              <Route
                path={`${baseUrl}/unconverted-ghg-amount`}
                element={<UnGhg />}
              />
              <Route path={`${baseUrl}/events`} element={<Events />} />
              <Route
                path={`${baseUrl}/register`}
                element={<EventsRegister />}
              />
              {data.email === 'nonamer@myself.com' ? (
                <Route path={`${baseUrl}/roles`} element={<Roles />} />
              ) : (
                ''
              )}

              <Route exact path={`${baseUrl}/future`} element={<Future />} />
              <Route exact path={`${baseUrl}/admin`} element={<Admin />} />
              <Route exact path={`${baseUrl}/lenders`} element={<Lenders />} />
              <Route
                exact
                path={baseUrl + '/investors'}
                element={<Investors />}
              />
              <Route
                exact
                path={baseUrl + '/margin-trade'}
                element={<MarginTrade />}
              />
              <Route
                path={baseUrl + '/tickets'}
                element={<TicketList />}></Route>
              <Route
                path={baseUrl + '/tickets/:id'}
                element={<SingleTicket />}
              />
              <Route
                exact
                path={baseUrl + '/crypto-pay'}
                element={<CryptoPay />}
              />
              <Route
                exact
                path={baseUrl + '/calender'}
                element={<Calendar />}
              />
              <Route exact path={baseUrl + '/chat'} element={<Chat />} />
              <Route
                exact
                path={baseUrl + '/bootstrap-from'}
                element={<BootStrapForm />}
              />
              <Route
                exact
                path={baseUrl + '/custome-form'}
                element={<CustomeForm />}
              />
              <Route
                exact
                path={baseUrl + '/vizard-form'}
                element={<VizardForm />}
              />
              <Route
                exact
                path={baseUrl + '/widget-example'}
                element={<WidgetExample />}
              />
              <Route
                exact
                path={baseUrl + '/chart-example'}
                element={<ChartsExample />}
              />
              <Route
                exact
                path={baseUrl + '/price-plane'}
                element={<PricePlane />}
              />
              <Route
                exact
                path={baseUrl + '/profile-page'}
                element={<ProfilePage />}
              />
              <Route
                exact
                path={baseUrl + '/table-example'}
                element={<TableExample />}
              />
              <Route exact path={baseUrl + '/help'} element={<Help />} />
              <Route
                exact
                path={baseUrl + '/salary-slip'}
                element={<SalarySlip />}
              />
              <Route
                exact
                path={baseUrl + '/documentation'}
                element={<Documentation />}
              />
              <Route
                exact
                path={baseUrl + '/expenses'}
                element={<Expenses />}
              />
              <Route
                exact
                path={baseUrl + '/changelog'}
                element={<Changelog />}
              />
              <Route exact path={baseUrl + '/invoice'} element={<Invoice />} />
              <Route
                exact
                path={baseUrl + '/stater-page'}
                element={<StaterPage />}
              />
              <Route
                exact
                path={baseUrl + '/icofont-icon'}
                element={<Icons />}
              />
              <Route
                exact
                path={baseUrl + '/bootstrap-icon'}
                element={<BootStrapIcon />}
              />
              <Route
                exact
                path={baseUrl + '/font-awesome-icon'}
                element={<FontAwesomeIcon />}
              />
              <Route
                exact
                path={baseUrl + '/todo-list'}
                element={<ToDoList />}
              />
              <Route
                exact
                path={baseUrl + '/wallet-page'}
                element={<WalletPage />}
              />
              <Route
                exact
                path={baseUrl + '/reward-page'}
                element={<Reward />}
              />
              <Route
                exact
                path={baseUrl + '/security-page'}
                element={<SecurityPage />}
              />
              <Route
                exact
                path={baseUrl + '/identification'}
                element={<Identification />}
              />
              <Route
                exact
                path={baseUrl + '/refer-page'}
                element={<ReferalPage />}
              />
              <Route exact path={baseUrl + '/ui-alerts'} element={<Alerts />} />
              <Route exact path={baseUrl + '/ui-badge'} element={<Badges />} />
              <Route
                exact
                path={baseUrl + '/ui-breadcrumb'}
                element={<Breadcrumb />}
              />
              <Route
                exact
                path={baseUrl + '/ui-buttons'}
                element={<Buttons />}
              />
              <Route exact path={baseUrl + '/ui-card'} element={<Cards />} />
              <Route
                exact
                path={baseUrl + '/ui-carousel'}
                element={<Carousel />}
              />
              <Route
                exact
                path={baseUrl + '/ui-collapse'}
                element={<Collapse />}
              />
              <Route
                exact
                path={baseUrl + '/ui-dropdowns'}
                element={<Dropdowns />}
              />
              <Route
                exact
                path={baseUrl + '/ui-listgroup'}
                element={<ListGroup />}
              />
              <Route
                exact
                path={baseUrl + '/ui-modalui'}
                element={<ModalUI />}
              />
              <Route
                exact
                path={baseUrl + '/ui-navbarui'}
                element={<NavbarUI />}
              />
              <Route exact path={baseUrl + '/ui-navsui'} element={<NavsUI />} />
              <Route
                exact
                path={baseUrl + '/ui-paginationui'}
                element={<PaginationUI />}
              />
              <Route
                exact
                path={baseUrl + '/ui-popoversui'}
                element={<PopoversUI />}
              />
              <Route
                exact
                path={baseUrl + '/ui-progressui'}
                element={<ProgressUI />}
              />
              <Route
                exact
                path={baseUrl + '/ui-Scrollspyui'}
                element={<Scrollspy />}
              />
              <Route
                exact
                path={baseUrl + '/ui-spinnersui'}
                element={<SpinnersUI />}
              />
              <Route
                exact
                path={baseUrl + '/ui-toastsui'}
                element={<ToastsUI />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    );
  }
  return content;
};

export default MainIndex;
