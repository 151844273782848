import { createSlice } from "@reduxjs/toolkit";

const localToken = sessionStorage.getItem("token");
const initialState = { token: localToken };
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      const { access_token } = action.payload;
      state.token = access_token;
      sessionStorage.setItem("token", access_token);
      sessionStorage.setItem("last_login", new Date().toISOString());
    },
    logOut: (state) => {
      state.token = null;
      sessionStorage.clear();
    },
  },
});

export const { setAccessToken, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentToken = (state) => state.auth.token;
