export const LESSONS_TAG = 'Lessons';
export const COURSES_TAG = 'courses';
export const CATEGORIES_TAG = 'categories';
export const BUSINESSES_TAG = 'businesses';
export const TICKETS_TAG = 'tickets';
export const LOANS_TAG = 'loans';

export const LOAN_PRODUCTS = 'loan_products';

export const LOAN_TRANSACTIONS = 'loan_transactions';

export const SURVEY_FORM = 'survey';

export const SURVEY_RESULTS = 'survey_results';

export const POSTS = 'posts';

export const ROLES = 'roles';

export const FAQS_TAG = 'faqs';

export const EXPERTS_TAG = 'experts';

export const FINANCIAL_PROVIDERS_TAG = 'financial_providers';

export const MARKET_TAG = 'marketplace';

export const VENDORS = 'vendors';

export const APPS = 'apps';

export const PROGRAMS = 'programs';

export const QUESTIONS = 'questions';
export const OPTIONS = 'options';
export const RATINGS = 'ratings';
export const STATEMENTS = 'statements';

export const CREDIT_RECO = 'credit_reco';

export const BUS_RECO = 'bus_reco';

export const CREDIT_SCORE = 'credit_score';
export const BUSINESS_SCORE = 'business_score';

export const SMT_TYPE = 'smt_type';

export const CRD_SCORE = 'crd_score';

export const SMT_CATEGORY = 'smt_category';

export const EVENTS_TAG = 'events';
