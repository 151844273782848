import React, { createRef } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import ButtonLoader from '../../Buttons/ButtonLoader';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getDate, uploadMpesaSchema } from './finance-schema';
import { useUploadStateMutation } from '../../../Redux/features/user/uploads';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const MpesaForm = ({ statementTypes, userId }) => {
  const [uploadState, { isLoading: loading }] = useUploadStateMutation();
  const docRef = createRef();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(uploadMpesaSchema),
    mode: 'onChange',
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = async data => {
    const { pdf, start_date, end_date, ...rest } = data;
    const inputData = {
      pdf: pdf[0],
      start_date: getDate(start_date),
      end_date: getDate(end_date),
      ...rest,
    };
    const type = 'mpesa';
    try {
      await uploadState({ statement: inputData, type, userId }).unwrap();
      toast.success('Your Mpesa statement has been uploaded successfully');
      reset();
      goBack();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <div className='col-lg-12'>
      <div className='card'>
        <div className='card-body'>
          <div className='wizard-main' id='w-horizontal'>
            <div className='step-app'>
              <div className='step-content'>
                <div className={`step-tab-panel active`} data-step='step1'>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    id='upload-mpesa-form'>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <Form.Label className='col-form-label'>
                          Statement type
                        </Form.Label>
                        <Form.Select
                          {...register('statement_type_id')}
                          onChange={e =>
                            setValue('statement_type_id', e.target.value, {
                              shouldValidate: true,
                            })
                          }
                          aria-label='Statement Type'>
                          {statementTypes.map(smt => (
                            <option key={smt.value} value={smt.value}>
                              {smt.label}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.statement_type_id?.message && (
                          <Form.Text>
                            {errors.statement_type_id?.message}
                          </Form.Text>
                        )}
                      </div>
                      <div className='col-md-6 mb-3'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type={'date'}
                          placeholder='Enter event date'
                          {...register('start_date')}
                        />
                        {errors.start_date?.message && (
                          <Form.Text>{errors.start_date?.message}</Form.Text>
                        )}
                      </div>
                      <div className='col-md-6  mb-3'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type={'date'}
                          placeholder='Enter event date'
                          {...register('end_date')}
                        />
                        {errors.end_date?.message && (
                          <Form.Text>{errors.end_date?.message}</Form.Text>
                        )}
                      </div>
                      <div className='col-md-6 mb-3'>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type={'telephone'}
                          placeholder='Enter event date'
                          {...register('phone_number')}
                        />
                        {errors.phone_number?.message && (
                          <Form.Text>{errors.phone_number?.message}</Form.Text>
                        )}
                      </div>
                      <div className='col-md-6 mb-3'>
                        <Form.Label>Document Pin</Form.Label>
                        <Form.Control
                          type={'text'}
                          placeholder='Enter document pin'
                          {...register('pin')}
                        />
                        {errors.pin?.message && (
                          <Form.Text>{errors.pin?.message}</Form.Text>
                        )}
                      </div>
                      <div className={'col-md-6 mb-3'}>
                        <Form.Label>Upload document</Form.Label>
                        <Form.Control
                          ref={docRef}
                          type='file'
                          name={'pdf'}
                          {...register('pdf')}
                        />
                        {errors.pdf?.message && (
                          <Form.Text>{errors.pdf?.message}</Form.Text>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-danger mx-5'
                  style={{ color: 'white' }}
                  onClick={goBack}>
                  Close
                </button>
                <button
                  disabled={!isDirty || !isValid || loading}
                  type={'submit'}
                  form={'upload-mpesa-form'}
                  className='btn btn-primary'>
                  <ButtonLoader isLoading={loading} text={'Upload'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MpesaForm;
