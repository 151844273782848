import React from 'react';
import { Spinner } from 'react-bootstrap';

const ButtonLoader = ({ isLoading, text }) =>
  isLoading ? (
    <Spinner
      as='span'
      animation='grow'
      size='sm'
      role='status'
      aria-hidden='true'
      className='me-1'
    />
  ) : (
    <>{text}</>
  );

export default ButtonLoader;
