import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { LOANS_TAG } from '../../../constants';

const loansAdapter = createEntityAdapter();
const initialState = loansAdapter.getInitialState();

export const loansApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAllLoans: build.query({
      query: () => `/loan/application/list`,
      transformResponse: rawResult => rawResult?.response,
    }),
    getLoanById: build.query({
      query: id => `/loan/application/${id}`,
      transformResponse: rawResult => rawResult?.response,
    }),
    getLoansByBusinessId: build.query({
      query: id => `/loan/application/list/${id}`,
      transformResponse: rawResult =>
        loansAdapter.setAll(initialState, rawResult?.response),
      providesTags: (result, error, arg) => [
        ...result.ids.map(id => ({ type: LOANS_TAG, id })),
      ],
    }),
    getLoanApproval: build.query({
      query: args => `/loan/${args.userId}/approval/${args.id}`,
      transformResponse: rawResult => rawResult?.response,
    }),
  }),
});

export const {
  useGetAllLoansQuery,
  useGetLoansByBusinessIdQuery,
  useGetLoanByIdQuery,
  useGetLoanApprovalQuery,
} = loansApiSlice;
