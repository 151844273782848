import React, { useState } from 'react';
import { useGetStatementTypesQuery } from '../../../Redux/features/statements/statementTypesApiSlice';
import { CustomLoader } from '../../../Screen/LoanApplicationsList/LoanApplicationsList';
import MpesaForm from './mpesa-form';
import BankForm from './bank-form';
import FinancialStatement from './financial-statement';
import { useParams } from 'react-router-dom';

const EditFinances = () => {
  const { id: userId } = useParams();

  console.log({ userId });
  let mpesaStatementTypes = [];
  let bankStatementTypes = [];
  let financialStatementType;
  const { isLoading, isError, error, isSuccess, data } =
    useGetStatementTypesQuery();
  const [isBank, setIsBank] = useState(true);
  const [isMpesa, setIsMpesa] = useState(false);
  const [isFinancial, setIsFinancial] = useState(false);

  if (isLoading) return <CustomLoader />;
  if (isError) {
    console.log(error);
    return <div>Something went wrong</div>;
  }
  if (isSuccess) {
    console.log({ data });
    const non_financial = data?.data?.filter(
      smt => !smt.name.toLocaleLowerCase().includes('financial_statement'),
    );
    const temp = data?.data.find(smt =>
      smt.name.toLocaleLowerCase().includes('financial_statement'),
    );

    if (temp) {
      financialStatementType = {
        value: temp.id,
        label: temp.name.toLocaleLowerCase().replace(/_/g, ' '),
      };
    }

    non_financial.map(smt =>
      smt.description.toLocaleLowerCase().includes('mpesa')
        ? mpesaStatementTypes.push({
            value: smt.id,
            label: smt.name.toLocaleLowerCase().replace(/_/g, ' '),
          })
        : bankStatementTypes.push({
            value: smt.id,
            label: smt.name.toLocaleLowerCase().replace(/_/g, ' '),
          }),
    );
  }

  const onIsBankChange = () => {
    setIsBank(!isBank);
    setIsMpesa(false);
    setIsFinancial(false);
  };

  const onIsMpesaChange = () => {
    setIsMpesa(!isMpesa);
    setIsBank(false);
    setIsFinancial(false);
  };

  const onIsFinancialChange = () => {
    setIsFinancial(!isFinancial);
    setIsBank(false);
    setIsMpesa(false);
  };

  return (
    <div>
      <div class='d-flex items-center gap-3 align-items-center'>
        <div>
          <input
            id={'bank'}
            type='radio'
            checked={isBank}
            onChange={onIsBankChange}
          />
          <label htmlFor={'bank'}>Bank</label>
        </div>
        <div>
          <input
            id={'m-pesa'}
            type='radio'
            checked={isMpesa}
            onChange={onIsMpesaChange}
          />
          <label htmlFor={'m-pesa'}>Mpesa</label>
        </div>
        <div>
          <input
            id={'financial'}
            type='radio'
            checked={isFinancial}
            onChange={onIsFinancialChange}
          />
          <label htmlFor={'financial'}>Financial Statement</label>
        </div>
      </div>
      <div className={'mt-3'}>
        {isMpesa && (
          <MpesaForm statementTypes={mpesaStatementTypes} userId={userId} />
        )}
        {isBank && (
          <BankForm statementTypes={bankStatementTypes} userId={userId} />
        )}
        {isFinancial && (
          <FinancialStatement
            statementType={financialStatementType}
            userId={userId}
          />
        )}
      </div>
    </div>
  );
};

export default EditFinances;
